<template>
  <div
    :class="[
      header.value === 'order' && 'table-cell-wrapper__order',
      header.value === 'order' && getActiveRowIds.includes(item.id) && 'table-cell-wrapper__order--active',
      `order-cell--${getSelectedRowHeight}`
    ]"
    @mouseleave="onRemoveHeader"
    @mouseover="onSetHeader(header.value)">
    <template v-if="header.value === 'order'">
      <!-- line that shows during comparison -->
      <TableActionsCompareModified
        v-if="isResultCompare && compareData.status"
        ref="testOrderActions"
        :alert-status="compareData.status"
        :row-id="item.id"
        :table-id="tableId" />

      <!-- checkbox -->
      <ControlSelects
        v-if="useScheduleMultipleSelect"
        :value="checkIsRowSelected(item.id)"
        @change="(v) => onSelectedRowsChange(item.id , v) " />

      <!-- options -->
      <ProductCellOrder
        :order="item.order">
        <template
          v-if="showProjectDetailActions"
          #actions>
          <v-icon
            medium
            color="black"
            class="brightness-icon"
            @click="$emit('setMenuItem', {
              item,
              event: $event,
            })">
            mdi-dots-vertical
          </v-icon>
        </template>
      </ProductCellOrder>

      <!-- expand -->
      <ProductCellExpandedView v-on="$listeners" />
    </template>

    <ProductCell
      v-else
      :id="`${item.id}--${header.value}`"
      ref="tableCell"
      :bordered-cell="borderedCell"
      :class="[
        getActiveRowIds.includes(item.id) && 'table-container__active-cell',
        isCellSearchHighlighting({ rowId: item.id, columnName: header.value }) && 'v-data-table--schedule__search-highlighting',
        isSelectedCellSearchHighlighting({ rowId: item.id, columnName: header.value }) && 'v-data-table--schedule__search-highlighting__selected',
        renderedDraggableIndex == index && !canBeActiveResized(index) && 'v-data-table--schedule__dragged-cell',
        `table-cell--${getSelectedRowHeight}`
      ]"
      :data="data"
      :disabled-edit-mode="disabledEditMode || item.isCompared"
      :header="header"
      :is-expanded-mode="false"
      :is-opened-in-dialog="false"
      :item="item"
      :transform-images-to-data="transformImagesToData"
      allow-save-on-esc
      allow-set-multuple-cells
      allow-show-select-col
      hide-header
      v-on="$listeners">
      <template
        #additionalCellActions="{ isCellForPasting: isPastingCell, borderedCell: isBorderedCell }">
        <ProductCellCopy
          v-if="!isUnsuccessfulPayment"
          :is-selected-cell="isBorderedCell"
          v-on="$listeners" />
        <ProductCellPaste
          v-if="isPastingCell && !isUnsuccessfulPayment"
          v-on="$listeners" />
      </template>
      <template #cellActions="{ doubleClickCell }">
        <v-icon
          class="pointer order-2"
          color="green"
          size="16"
          @click="doubleClickCell">
          mdi-pencil
        </v-icon>
      </template>
      <template #cellNotification>
        <ProductCellModifiedWarning
          v-if="isResultCompare && compareData.rowId === item.id && compareData.modified.includes(header.value)"
          ref="testActions"
          :row-id="item.id"
          :table-id="tableId" />
      </template>
    </ProductCell>
  </div>
</template>

<script>
import {
  mapGetters, mapState,
  mapMutations,
} from 'vuex';

import productHeaders from '@/constants/productHeaders';

import ControlSelects from '@/components/Product/ProductCell/ProductCellControlSelects';
import ProductCell from '@/components/Product/ProductCell';
import ProductCellCopy from '@/components/Product/ProductCell/ProductCellCopy';
import ProductCellExpandedView from '@/components/Product/ProductCell/ProductCellExpandedView';
import ProductCellModifiedWarning from '@/components/Product/ProductCell/ProductCellModifiedWarning';
import ProductCellOrder from '@/components/Product/ProductCell/ProductCellOrder';
import ProductCellPaste from '@/components/Product/ProductCell/ProductCellPaste';
import TableActionsCompareModified from '@/components/ProjectDetails/ProjectDetailsTableSchedule/TableActions/TableActionsCompareModified';

import activeCellsMixin from '@/mixins/activeCellsMixin';

export default {
  name: 'TableCellBlock',
  components: {
    ControlSelects,
    ProductCell,
    ProductCellCopy,
    ProductCellExpandedView,
    ProductCellModifiedWarning,
    ProductCellOrder,
    ProductCellPaste,
    TableActionsCompareModified,
  },
  mixins: [
    activeCellsMixin,
  ],
  props: {
    header: {
      type: Object,
      default: () => {
      },
    },
    index: {
      type: Number,
      default: 0,
    },
    item: {
      type: Object,
      default: () => {
      },
    },
    disabledEditMode: {
      type: Boolean,
      default: false,
    },
    renderedDraggableIndex: {
      type: Number,
      default: null,
    },
    showProjectDetailActions: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Array,
      default: () => [],
    },
    borderedCell: {
      type: Boolean,
      default: false,
    },
    isUnsuccessfulPayment: {
      type: Boolean,
      default: false,
    },
    transformImagesToData: {
      type: Boolean,
      default: false,
    },
    tableId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      productHeaders,
    };
  },
  computed: {
    ...mapGetters({
      isCellSearchHighlighting: 'SearchProjectCells/isCellSearchHighlighting',
      getSelectedRowHeight: 'ScheduleViews/getSelectedRowHeight',
      isResultCompare: 'ScheduleCompare/isResultCompare',
    }),
    ...mapGetters('FeatureFlags', [ 'useScheduleMultipleSelect']),
    ...mapState('ScheduleRows', ['checkboxSelectedRows']),
    selectedRowsIds: {
      get() {
        return this.checkboxSelectedRows;
      },
      set(val) {
        this.setCheckboxSelectedRows(val);
      },
    },
    compareData() {
      const {
        item = {
        },
      } = this;
      return item.compareData || {
      };
    },
  },
  methods: {
    ...mapMutations('ScheduleRows', [ 'setCheckboxSelectedRows']),
    onSelectedRowsChange(id, value) {
      if (value) {
        this.selectedRowsIds.push(id);
      } else {
        this.selectedRowsIds = this.selectedRowsIds.filter((itemId)=>itemId !== id);
      }
    },
    checkIsRowSelected(id) {
      return this.selectedRowsIds.includes(id);
    },
  },
};
</script>
