<template>
  <thead class="v-data-table-header">
    <draggable
      v-model="draggableHeaders"
      tag="tr"
      :draggable="!disabledEditMode && canDragAndDropColumns && '.v-data-table-header__draggable'"
      @start="onDragChoose"
      @unchoose="onDragUnchoose"
      @end="onDragEnd">
      <TableHeaderItem
        v-for="(head, index) in headers"
        :key="`${head.value}__custom-header`"
        :ref="`${head.value}__ref`"
        :column-id="head.column.id"
        :disabled-edit-mode="disabledEditMode"
        :header-min-size="head.columnSize ? head.columnSize.minSize : 0"
        :header-name="head.value"
        :header-size="head.columnSize ? head.columnSize.size : 0"
        :index="index"
        :is-allowed-to-resize="head.isAllowedToResize"
        :is-custom-sortable="head.isCustomSortable"
        :is-focused-on-actions="isFocusedOnActions"
        :is-resizable="head.isResized"
        :project-id="projectId"
        :rendered-draggable-ref="renderedDraggableRef.index === index ? renderedDraggableRef : null"
        :table-height="tableHeight"
        :th-class="head.thClass"
        @focused-on-actions="isFocusedOnActions = $event" />
    </draggable>
  </thead>
</template>
<script>
import 'vue-draggable-resizable/dist/VueDraggableResizable.css';
import DraggableMixin from '@/mixins/draggableMixin';
import draggable from 'vuedraggable';
import TableHeaderItem from '@/components/ProjectDetails/ProjectDetailsTableSchedule/TableHeader/TableHeaderItem';
export default {
  name: 'TableHeader',
  components: {
    draggable,
    TableHeaderItem,
  },
  mixins: [DraggableMixin],
  props: {
    projectId: {
      type: String,
      default: '',
    },
    disabledEditMode: {
      type: Boolean,
      default: false,
    },
    tableHeight: {
      type: [Number],
      default: 0,
    },
    headers: {
      type: Array,
      default: () => [],
    },
  },
  data: function() {
    return {
    };
  },
};
</script>
<style scoped lang="scss">
  @import "src/assets/styles/resizableElement.scss";
  $accent-color: rgba(#71d6e0, .2);
  $accent-color-header: #DEEFF1;
  $grey-color: rgba(#757575, .1);
  ::v-deep {
    // Changes background of header cell
    .table-container__header-cell {
      background: $grey-color;
      &--hover {
        background: $accent-color;
      }
      &--hover-active {
        background: $accent-color-header;
      }
    }
  }
</style>
