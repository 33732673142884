<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-menu
        :nudge-bottom="5"
        offset-y>
        <template v-slot:activator="{ on: menuOn, attrs: menuAttrs }">
          <div
            class="pointer nav-svg-icon icon-row-height"
            v-bind="{ ...attrs, ...menuAttrs }"
            v-on="{ ...on, ...menuOn }" />
        </template>
        <v-list>
          <v-list-item
            v-for="(_, index) in HEIGHT_OF_ROWS"
            :key="index"
            :class="{'v-item--active v-list-item--active': index === rowHeight }"
            @click="rowHeight = index">
            {{ index }}
          </v-list-item>
          <v-list-item class="pt-2">
            <v-btn
              class="elevation-0 white--text"
              color="black"
              @click.stop="openViewSettings">
              Update Default Height
            </v-btn>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
    <span>Change Row Height</span>
  </v-tooltip>
</template>

<script>
import { HEIGHT_OF_ROWS } from '@/constants/heightMappingTableValues';
import {
  mapGetters,
  mapMutations,
} from 'vuex';

export default {
  name: 'RowHeightAdjuster',
  data: () => ({
    HEIGHT_OF_ROWS,
  }),
  computed: {
    ...mapGetters('ScheduleViews', ['getSelectedRowHeight', 'selectedView']),
    rowHeight: {
      get() {
        return this.getSelectedRowHeight;
      },
      set(val) {
        this.setSelectedRowHeight(val);
      },
    },
  },
  methods: {
    ...mapMutations('ScheduleViews', ['setSelectedRowHeight']),
    openViewSettings() {
      this.$emit('openViewSettings', this.selectedView);
    },
  },
};
</script>